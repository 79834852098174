import React from 'react';
import WaveLoader from 'components/waveLoader';
// import ReactDOM from 'react-dom';
const LoadingComp = (props) => {
    // console.log(props, "size of loader")
    if (props.small != undefined && props.small == true) {
        return (
            <div className="loading-image d-flex align-items-center justify-content-center ">
                {/* <img src="/assets/img/loader-main-pink.gif" /> */}
                <WaveLoader size="small"/>
                </div>
        )
    }
    return (
        <div className="loading-image d-flex align-items-center justify-content-center vh-100">
            {/* <img src="/assets/img/loader-main-pink.gif" /> */}
            <WaveLoader size="large"/>
            </div>
    )
}
export default LoadingComp;