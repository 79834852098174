
import React, { useEffect, useState, useRef } from 'react';
// import styles from './stylist-suggestions-index.module.scss'
// import styles from '@/containers/home/stylist-suggestions/stylist-suggestions-index.module.scss'
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { getStylishPick, updateCustomerTags, getSizeInfo, getCustomerTags, getRangeBetweenTwoNumbers, generatePaginationData } from 'components/shared/globalFunctions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { updateProfile } from 'components/shared/customerDataFunctions';
import moment from 'moment';
import jsCookie from 'js-cookie';
interface IProps {
  isOpen: any;
  toggle: any;
  callBackFunc: any;
  setInCompleteProfile: any;
  btn_name: string;
  statesArr: any,
  selectedState: String
}

const UpdatePrefrencePopup: React.FC<IProps> = ({ isOpen, toggle, callBackFunc, setInCompleteProfile, btn_name, statesArr, selectedState }) => {

  const [oldProfileValues, setOldProfileValue] = useState({
    bodyType: '',
    dressSize: '',
    dressNumber: '',
    topSize: '',
    braSize: '',
    braSize1: '',
    jeansSize: '',
    phone: '',
    firstName: '',
    lastName: '',
    current_email: '',
    birthday: '',
    heightFt: '',
    heightIn: '',
    weight: ''
  })
  const [newProfileValues, setNewProfileValue] = useState({
    bodyType: '',
    dressSize: '',
    dressNumber: '',
    topSize: '',
    braSize: '',
    braSize1: '',
    jeansSize: '',
    phone: '',
    firstName: '',
    lastName: '',
    current_email: '',
    birthday: '',
    heightFt: '',
    heightIn: '',
    weight: ''
  })

  const [stateChangeValue, setStateChangeValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const customer = useAppSelector(state => state.customer.customer);
  const dispatch = useAppDispatch();

  useEffect(() => {

    if(localStorage.getItem('CustomerViewOrderState') != undefined && localStorage.getItem('CustomerViewOrderState') != "") {
      setStateChangeValue(localStorage.getItem('CustomerViewOrderState'))
    }

    let dressSize: any = '', bodyType = '', braSize = '', height_ft = '', height_in = '', top_size = '', jeans_size = '', weight = '', bs = ['', ''], ds = ['', ''], fname = "", lname = "", birthday = "", phone = "";
    let obj = {}
    let isInCompletePrefrence = false
    if (customer != "not_login") {

      customer.tag_list.map((tag: any) => {

        if (tag.indexOf('Dress Size:') > -1) {
          dressSize = tag.split(':')
          if (dressSize && dressSize.length > 0) {
            ds[1] = dressSize[1] ? dressSize[1] : ''
            ds[0] = dressSize[2] ? dressSize[2] : ''
          }
        }

        if (tag.indexOf('Jeans Size:') > -1) {
          jeans_size = tag.split(':')[1]
        }

        if (tag.indexOf('Body Type:') > -1) {
          bodyType = tag.split(':')[1]
        }

        if (tag.indexOf('Shirt & Top:') > -1) {
          top_size = tag.split(':')[1]
        }

        if (tag.indexOf('Height:') > -1) {
          let height = tag.split(':')[1]
          if (height && height.indexOf('-') > -1) {
            height_ft = height.split('-')[0]
            height_in = height.split('-')[1]
          }
        }

        if (tag.indexOf('Bra:') > -1) {
          braSize = tag.split(':')
          if (braSize && braSize.length > 0) {
            bs[0] = braSize[1] ? braSize[1] : ''
            bs[1] = braSize[2] ? braSize[2] : ''
          }
        }

        if (tag.indexOf('Weight:') > -1) {
          weight = tag.split(':')[1]
        }

        if (tag.indexOf('Birthday:') > -1) {
          birthday = tag.split(':')[1]
        }

      })

      obj = {
        bodyType: bodyType,
        dressSize: ds[0],
        dressNumber: ds[1],
        topSize: top_size,
        braSize: bs[0],
        braSize1: bs[1],
        jeansSize: jeans_size,
        phone: customer.phone_no,
        firstName: customer.first_name,
        lastName: customer.last_name,
        current_email: customer.email,
        birthday: birthday,
        heightFt: height_ft,
        heightIn: height_in,
        weight: weight,
        new_email: ""
      }

      setNewProfileValue(obj)
      setOldProfileValue(obj)

      if (top_size == '' || ds[0] == '' || ds[1] == '' || jeans_size == '') {
        //  setIncompleteProfileOverlay(true)
        //  isInCompletePrefrence = true
        setInCompleteProfile(true)
      }
      else {
        setInCompleteProfile(false)
      }
    }
  }, [])

  const handleInput = (e: any) => {
    let obj: any = { ...newProfileValues }
    obj[e.target.name] = e.target.value
    setNewProfileValue(obj)
  }

 const onStateSelect  = (e: any) => { 
  // localStorage.setItem('CustomerViewOrderState', e.target.value)
  // localStorage.setItem('CustomerViewOrderStateExpireTime', moment().add(24, 'hours').unix().toString())
  setStateChangeValue(e.target.value)
}

  const update = async () => {

  if(stateChangeValue != "" && stateChangeValue != undefined && stateChangeValue != null){
    localStorage.setItem('CustomerViewOrderState', stateChangeValue)
    localStorage.setItem('CustomerViewOrderStateExpireTime', moment().add(24, 'hours').unix().toString())
  }
  

    let addTags: any, removeTags: any, toAdd: any, toRemove: any;
    let oldValues = oldProfileValues;
    let curentValues = newProfileValues;

    addTags = [
      'Dress Size:' + curentValues.dressNumber + ':' + curentValues.dressSize,
      'Jeans Size:' + curentValues.jeansSize,
      'Shirt & Top:' + curentValues.topSize,
    ]
    removeTags = [
      'Dress Size:' + oldValues.dressNumber + ':' + oldValues.dressSize,
      'Jeans Size:' + oldValues.jeansSize,
      'Shirt & Top:' + oldValues.topSize,
    ]

    toAdd = addTags.filter((val: any) => !removeTags.includes(val))
    toRemove = removeTags.filter((val: any) => !addTags.includes(val))

    let profileValue = {
      "date_of_birth": moment(curentValues.birthday).format('YYYY-MM-DD'),
      "current_email": curentValues.current_email,
      "new_email": "",
      "phone_no": curentValues.phone,
      "body_type": curentValues.bodyType,
      "height_ft": curentValues.heightFt,
      "height_in": curentValues.heightIn,
      "weight": curentValues.weight,
      "dress_size": curentValues.dressSize + '-' + curentValues.dressNumber,
      "top_size": curentValues.topSize,
      "bra_size": curentValues.braSize + '-' + curentValues.braSize1,
      "jeans_size": curentValues.jeansSize
    }

    if (toAdd.length > 0 && toRemove.length > 0) {
      setIsLoading(true)
      updateProfile(profileValue).then(response => {
        if (response.success == true) {
          updateCustomerTags(toAdd.join(), toRemove.join()).then(() => {
            setIsLoading(false)
            dispatch(getCustomerTags(customer.customer_id)).then(() => {
              callBackFunc({ success: true, response: response, profileValue: profileValue, customer: customer })
            })
          }).catch(() => {
            callBackFunc({ success: false, response: null, profileValue: null, customer: customer })
            setIsLoading(false)
          })
          //   setprefrenceSuggestionsPopup(false)
        }else{
          setIsLoading(false)
        }
      }).catch(() => {
        callBackFunc({ success: false, response: null, profileValue: null, customer: customer })
        setIsLoading(false)
      })
    }
    else {
      callBackFunc({ success: true, response: null, profileValue: profileValue, customer: customer })
    }

  }

  return (
    <>
      <Modal className={"prefrences_container_main_SFL"} size="lg" centered isOpen={isOpen} toggle={() => { toggle() }}>
        <ModalHeader className={"prefrences_modal_head"} toggle={() => { toggle() }}>
        </ModalHeader>
        <ModalBody className={"prefrences_container"}>
          <div className={"prefrences_text_box"}>
            <h2>Your Preferences</h2>
            <p>We are suggesting products based on the following info:</p>
          </div>
          <div className={"prefrences_drop_down_box"}>
            <div className={"prefrences_div_one"}>
              <div className={"drop_box_inner_div_one"}>
                <label>
                  <span>Dress Size</span>
                  <select name="dressSize" onChange={(e) => handleInput(e)} value={newProfileValues.dressSize} >
                    <option value="" disabled > -- </option>
                    <option value="XXS">XXS</option>
                    <option value="XS">XS</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                    <option value="3XL">3XL</option>
                    <option value="4XL">4XL</option>
                    <option value="5XL">5XL</option>
                  </select>
                </label>

                <label>
                  <span style={{ visibility: "hidden" }}>.</span>
                  <select name="dressNumber" onChange={(e) => handleInput(e)} value={newProfileValues.dressNumber}>
                    <option value="" disabled > -- </option>
                    <option value="00">00</option>
                    <option value="0">0</option>
                    <option value="2">2</option>
                    <option value="4">4</option>
                    <option value="6">6</option>
                    <option value="8">8</option>
                    <option value="10">10</option>
                    <option value="12">12</option>
                    <option value="14">14</option>
                    <option value="16">16</option>
                  </select>
                </label>
              </div>
            </div>
            <div className={"prefrences_div_two"}>
              <div className={"drop_box_inner_div_three"}>
                <label>
                  <span>Jean Size</span>
                  <select name="jeansSize" onChange={(e) => handleInput(e)} value={newProfileValues.jeansSize}>
                    <option value="" disabled > -- </option>
                    <option value="23">23</option>
                    <option value="24">24</option>
                    <option value="25">25</option>
                    <option value="26">26</option>
                    <option value="27">27</option>
                    <option value="28">28</option>
                    <option value="29">29</option>
                    <option value="30">30</option>
                    <option value="31">31</option>
                    <option value="32">32</option>
                    <option value="34">34</option>
                    <option value="36">36</option>
                  </select>
                </label>
                <label>
                  <span>Top Size</span>
                  <select name="topSize" onChange={(e) => handleInput(e)} value={newProfileValues.topSize}>
                    <option value="" disabled > -- </option>
                    <option value="XXS">XXS</option>
                    <option value="XS">XS</option>
                    <option value="S">S</option>
                    <option value="M">M</option>
                    <option value="L">L</option>
                    <option value="XL">XL</option>
                    <option value="XXL">XXL</option>
                    <option value="3XL">3XL</option>
                    <option value="4XL">4XL</option>
                    <option value="5XL">5XL</option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          {statesArr != undefined && Object.values(statesArr).length > 0  ?
          <div className={"prefrences_drop_down_box"} style={{ flexDirection:"column" }}>
            <div className={"prefrences_div_two"} style={{ flexDirection:"column" }}>
                <div className={"drop_box_inner_div_three"}>
                  <label>
                    <span className='d-block text-left'>State</span>
                    <select style={{ width:"250px" }} name="State" onChange={(e) => onStateSelect(e)} value={(stateChangeValue != undefined && stateChangeValue != "") ? stateChangeValue : selectedState}  >
                      <option value="" disabled > -- </option>
                      {
                        Object.values(statesArr).map((state:any, ind:any) => {
                          return (
                            <option value={state}> {state} </option>
                          )
                        })
                      }
                    </select>
                  </label>
                </div>
            </div>
          </div>  
             : "" }

          <div className={"prefrences_button_box"}>
            <button onClick={update} disabled={isLoading}>{btn_name}</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

export default UpdatePrefrencePopup;