import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Gconfig from "globalconfig";
import Login from 'components/shared/login';
import jsCookie from 'js-cookie';
import Router from 'next/router';

class SignupPopup extends Component {

    setPrevUrlForSignup() {
        Router.push(Gconfig.waitlistURL)
        let url = location.pathname
        jsCookie.set('prevWebsiteUrl', url)
    }
    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} size='md' centered className="signupModal">
                    <ModalHeader toggle={this.props.toggle}></ModalHeader>
                    <ModalBody>
                        <div className="bodyContent">
                            {/* <h3>Your Favorites</h3> */}
                            <span>Create an account to <i className="fas fa-heart"></i> your favorite items.</span>
                            <button onClick={() => {this.setPrevUrlForSignup()}} >Create my favorites List</button>
                            <div><label >Already have an account? </label><span> &nbsp;Log in <span onClick={this.props.openLogin}>here</span>.</span></div> 
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default SignupPopup;