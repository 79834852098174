import { useEffect } from 'react';

type windowEventsType = 'scroll' | 'resize' | 'click';
type functionEventTypes = Event | UIEvent | MouseEvent;

export const useWindowEvent = (event: windowEventsType, handleEventListener: (event: functionEventTypes) => void, options?: boolean | AddEventListenerOptions | undefined) => {
  useEffect(() => {
    window.addEventListener(event, handleEventListener.bind(this), options);
    return window.removeEventListener(event, handleEventListener.bind(this), options);
  }, []);
};
