import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import OwlCarousel from 'react-owl-carousel2fix';
import "react-owl-carousel2fix/lib/styles.css";
import "react-owl-carousel2fix/src/owl.carousel.css";
import "react-owl-carousel2fix/src/owl.theme.default.css";
import Gconfig from 'globalconfig.js';

const bodytype_slider = {
    items: 1,
    slideBy: 1,
    loop: true,
    nav: true,
    autoplay: false,
    dots: true,
    navText: ["", ""],
};

class BodyTypePopup extends Component {
    onModalClose = () => {
        document.body.removeAttribute('style')
    }
    render() {
        return (
            <div>
                <Modal onClosed={()=>{this.onModalClose()}} isOpen={this.props.modal} size="sm" toggle={this.props.toggle} centered className="bodyTypeModal">
                    <ModalBody>
                        <OwlCarousel options={bodytype_slider}>
                            <div>
                                <img className="blurFilter" src={Gconfig.fashionpassImageStorage + 'assets/img/rectangle.jpg?profile=a'} />
                            </div>
                            <div>
                                <img className="blurFilter" src={Gconfig.fashionpassImageStorage + 'assets/img/inverted.jpg?profile=a'} />
                            </div>
                            <div>
                                <img className="blurFilter" src={Gconfig.fashionpassImageStorage + 'assets/img/hourglass.jpg?profile=a'} />
                            </div>
                            <div>
                                <img className="blurFilter" src={Gconfig.fashionpassImageStorage + 'assets/img/triangle.jpg?profile=a'} />
                            </div>
                        </OwlCarousel>
                    </ModalBody>
                    <ModalFooter className="text-center m-0">
                        <Button color="light" className="col-12" onClick={this.props.toggle}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        )
    }
}

export default BodyTypePopup