import React, { useEffect, useState, useRef } from 'react';
import styles from './customer-order-feed-index.module.scss'
import BodyTypePopup from 'components/shared/bodyTypePopup';
import { Modal, ModalHeader, ModalBody, Spinner } from 'reactstrap';
import { getStylishPick, updateCustomerTags, getSizeInfo, getCustomerTags, getRangeBetweenTwoNumbers, generatePaginationData, getCustomerFeed, getStateAgaintZipCode, isShipmentLimitReched, saveCustomerViewProductActivityLocal, addCustomerViewProductActivity, capitializeString, isCartLimitReached } from 'components/shared/globalFunctions';
import { modal } from 'webGL/utils/html';
import Gconfig from 'globalconfig';
import dynamic from 'next/dynamic';
const MediaQuery = dynamic(() => import('react-responsive'), { ssr: false },);
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { getCustomerDetailInfo, getCustomerAddresses, updateProfile, getProvinceCode } from 'components/shared/customerDataFunctions';
import { useAppDispatch, useAppSelector } from '@/hooks/redux-hooks';
import { triggerCartPopper } from "redux/actions/cartActions";
import { addProductToCart, addMultipleProductToCart, addProductToCartFromFunctionComp, addSaveLaterProduct, updateSaveLaterProduct } from "components/shared/cartFunctions"
import BoardsListTooltip from 'components/shared/boardsListTooltip';
import { addRemoveFavoriteItem } from 'components/shared/productFavFunctions';
import SignupPopup from 'components/shared/signupPopup';
import Link from 'next/link';
import { Pagination, Navigation, FreeMode } from 'swiper';
import moment from 'moment';
import { coinFlipLogic } from '@/functions/customer-functions';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import Loader from 'components/shared/loadingComp';
import UpdatePrefrencePopup from 'components/shared/updatePrefrencesPopup';
import { fetchCartSaveLaterSuccess } from 'redux/actions/cartActions'
import { useWindowEvent } from '@/hooks/use-window-event';
import jsCookie from 'js-cookie';


var isLoadingData = false;
let statesFromCode = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "ky": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming"
}

interface IProps {
  // fromNew:boolean
  fromMyOrder:boolean
}
var alreadyViewProductArr:any = []
var tempViewProdArrObj:any = []

const CustomerOrderFeed: React.FC<IProps> = ({ fromMyOrder }) => {

  const [stylistSuggestionsPopup, setstylistSuggestionsPopup] = useState(false);
  const [prefrenceSuggestionsPopup, setprefrenceSuggestionsPopup] = useState(false)
  const [selectedCustomerFeedObj, setSelectedCustomerFeedObj] = useState([])
  const [allCustomerFeedOrderData, setAllCustomerFeedOrderData] = useState([])
  const [msg, setMsg] = useState({ type: "", msg: "" })
  const [openSignupModal, setOpenSignupModal] = useState(false)
  const [inCompleteProfileOverlay, setIncompleteProfileOverlay] = useState(false)
  const [bodyTypePopup, setBodyTypePopup] = useState(false)
  const [noOfIteminPerSlide, setNoOfIteminPerSlide] = useState(3)
  const [currentSwiperSlide, setCurrentSwiperSlide] = useState(0)
  const [alreadyFetchingNewData, setAlreadyFetchingNewData] = useState(false)
  // const [currentPage, setCurrentPage] = useState(0)
  const [compSetProfile, setCompSetProfile] = useState(false)
  const [minimumReqOrdersFound, setMinimumReqOrdersFound] = useState(true)
  const [selectedState, setSelectedState] = useState('')
  const [isPairNotFound, setIsPairNotFound] = useState(false)
  const [lastrecordcount, setLastrecordcount] = useState(0)
  const [alreadFetchedAllPairData, setAlreadyFetchedAllPairData] = useState(false)
  const [showSaveForLaterBtn, setShowSaveForLaterBtn] = useState(false)
  const [showSizeLaterPopupIndex, setShowSizeLaterPopupIndex] = useState(-1)
  const [saveForLaterTooltip, setSaveForLaterTooltip] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  // const isLoadingData = useRef(false)
  const customer = useAppSelector(state => state.customer.customer);
  const customerBoards = useAppSelector(state => state.customerBoards);
  const customerPrefrence = useAppSelector((state) => state.customerPreferences);
  const customerAddresses = useAppSelector((state) => state.customerAddresses.addresses)
  const cart = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  useWindowEvent(
    'click',
    (e) => {
      try {
        if (e?.target?.className && e?.target?.className.indexOf('save_later_size_btn') == -1) {
          setShowSizeLaterPopupIndex(-1)
        }
      }
      catch (e) {
        console.log(e)
      }
    },
    true
  )

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1190 && window.innerWidth > 767);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {

    if ((customerAddresses.length > 0 || customer.default_payment_method != undefined) && isLoadingData == false) {
      isLoadingData = true
      getCustomerFeedBySize(false);
    }

    if (customer != 'not_login') {
      if (isShipmentLimitReched(customer) == false && (customer?.tag_list.indexOf('CurrentlyBorrowing') == -1 || (customer?.tag_list.indexOf('CurrentlyBorrowing') > -1 && (customer?.tag_list?.join()?.indexOf('items') > -1  || customer?.tag_list.indexOf('2upgradeItems') > -1 || customer?.tag_list.indexOf('4upgradeItems') > -1 )))) {
        setShowSaveForLaterBtn(false)
      } else {
        setShowSaveForLaterBtn(true)
      }
    }



    if (screen.width <= 767) {
      const element = document.getElementsByTagName("body")[0];
      element.addEventListener("touchmove", (e: any) => {
        try {
          if (e.target.className && e.target.className.indexOf('newBoard-tooltip') == -1 && e.target.className.indexOf('fa-heart') == -1 && e.target.className.indexOf('boardItem') == -1) {
            let boardsList = document.querySelectorAll('.newBoard-tooltip')
            boardsList.forEach((val, ind) => {
              if (boardsList[ind]) {
                boardsList[ind].style.display = "none"
              }
            })
          }
        } catch (error) {
          console.log(error);
        }
      });
      return () => element.removeEventListener('touchmove', () => { })
    }

  }, [customerAddresses, customer.default_payment_method])
  //customerAddresses, customer.default_payment_method


  const getCustomerFeedBySize = async (isRefresh: any) => {
     
    let dressSize: any = '', bodyType = '', braSize = '', height_ft = '', height_in = '', top_size = '', jeans_size = '', weight = '', bs = ['', ''], ds = ['', ''], fname = "", lname = "", birthday = "", phone = "";
    let obj: any = {}
    let isInCompletePrefrence = false

    if (localStorage.getItem('CustomerViewOrderStateExpireTime') != null && localStorage.getItem('CustomerViewOrderStateExpireTime') != undefined && localStorage.getItem('CustomerViewOrderState') != undefined) {
      let expiry = localStorage.getItem('CustomerViewOrderStateExpireTime') != null ? localStorage.getItem('CustomerViewOrderStateExpireTime') : 1;
      let currentTime = moment().unix()
      if (parseInt(expiry) <= parseInt(currentTime)) {
        localStorage.removeItem('CustomerViewOrderState')
        localStorage.removeItem('CustomerViewOrderStateExpireTime')
      }
    }

    setMinimumReqOrdersFound(true)
    if (customer != "not_login") {

      customer.tag_list.map((tag: any) => {

        if (tag.indexOf('Dress Size:') > -1) {
          dressSize = tag.split(':')
          if (dressSize && dressSize.length > 0) {
            ds[1] = dressSize[1] ? dressSize[1] : ''
            ds[0] = dressSize[2] ? dressSize[2] : ''
          }
        }

        if (tag.indexOf('Jeans Size:') > -1) {
          jeans_size = tag.split(':')[1]
        }

        if (tag.indexOf('Shirt & Top:') > -1) {
          top_size = tag.split(':')[1]
        }

      })

      obj = {
        dressSize: ds[0],
        dressNumber: ds[1],
        topSize: top_size,
        jeansSize: jeans_size,
      }
      // debugger
      if (top_size == '' || ds[0] == '' || ds[1] == '' || jeans_size == '') {
        setIncompleteProfileOverlay(true)
        isInCompletePrefrence = true
      }
    }

    let default_state = ""

    // if(customerAddresses.length == 0) {
    //  await dispatch(getCustomerAddresses())
    // }

    if (localStorage.getItem('CustomerViewOrderState') == undefined || localStorage.getItem('CustomerViewOrderState') == null) {
      if (customerAddresses && customerAddresses.length > 0) {
       
        let isStateSelectionDone = 0
        customerAddresses.map(selected => {
          if (selected.default_address && selected.is_shipping) {
            default_state = selected.state
            isStateSelectionDone = 1
          }
        })

        if (customer.default_payment_method && isStateSelectionDone == 0) {
          let zipcode = customer.default_payment_method.zip || -1
          if (zipcode != -1 && zipcode != "" && zipcode != null && zipcode != undefined) {
            let res = await getStateAgaintZipCode(zipcode)
            if (res.success) {
              let statCode = res.response.state
              default_state = statesFromCode['' + statCode.toUpperCase()]
            }
          }
        }


      }
      else {

        // if(customer.default_payment_method == undefined) {
        //   await dispatch(getCustomerAddresses())
        //  }

        if (customer.default_payment_method) {
          let zipcode = customer.default_payment_method.zip || -1
          if (zipcode != -1 && zipcode != "" && zipcode != null && zipcode != undefined) {
            let res = await getStateAgaintZipCode(zipcode)
            if (res.success) {
              let statCode = res.response.state
              default_state = statesFromCode['' + statCode.toUpperCase()]
            }
          }
        }
      }
    }
    else {
      let customeSelectedState = localStorage.getItem('CustomerViewOrderState')
      default_state = customeSelectedState
    }



    if (default_state == "") {
      setAllCustomerFeedOrderData([])
      isLoadingData = false
      return
    }

    setSelectedState(default_state)

    default_state = default_state?.replaceAll(' ', '')

    let prefrenceString = `top_size=${obj.topSize}&dress_size=${obj.dressSize}&jeans_size=${obj.jeansSize}&StateName=${default_state}&lastrecordcount=${isRefresh ? 0 : lastrecordcount}`;
    if (isInCompletePrefrence) {
      prefrenceString = `top_size=&dress_size=&jeans_size=&StateName=${default_state}&lastrecordcount=${isRefresh ? 0 : lastrecordcount}`;
    }

    // isLoadingData.current = true
    let res = await getCustomerFeed(prefrenceString);
    isLoadingData = false
    // isLoadingData.current = false
    setAlreadyFetchingNewData(false)
    if (res.success) {

      if (res.total_orders == 0 || res.pair_found == false) {
        setIncompleteProfileOverlay(true)
        setIsPairNotFound(true)
      }
      else {
        setIsPairNotFound(false)
        if (top_size != '' || ds[0] != '' || ds[1] != '' || jeans_size != '') {
          setIncompleteProfileOverlay(false)
        }
      }

      let customersOrderArr: any = (isRefresh == true) ? [] : allCustomerFeedOrderData
      // let customersOrderArr:any = []

      let orderArr: any = []
      let orderObj = res.orders
      setLastrecordcount(res.lastrecordcount + 1)
      if (res.total_orders > 0 && orderObj != undefined && orderObj != "null") {
        let orderValuesArr = Object.values(orderObj)
        orderArr = orderValuesArr
      }

      if (res.total_orders < 40) {
        setAlreadyFetchedAllPairData(true)
      } else {
        setAlreadyFetchedAllPairData(false)
      }

      customersOrderArr = customersOrderArr.concat(orderArr)
      if (customersOrderArr.length >= 3) {
        setMinimumReqOrdersFound(true)
      }
      else {
        if (customersOrderArr[0] != undefined) {
          customersOrderArr[1] = customersOrderArr[0]
          customersOrderArr[2] = customersOrderArr[0]
        }
      }

      if (customersOrderArr.length == 0) setMinimumReqOrdersFound(false)
      let customersNewOrderArr: any = []
      customersOrderArr?.length > 0 && customersOrderArr?.map((order,orderInd)=>{
        let isTestProd = false;
        if(order?.ordersFeedProductPairs?.length > 0){
          let rentItemCount = 0
          order?.ordersFeedProductPairs?.map((item,itemIndex)=>{
            rentItemCount += 1
            if(item?.product_title && ''+item?.product_title?.toLowerCase()?.indexOf('test') > -1){
              isTestProd = true
            }
          })
          if(isTestProd == false){
            order.totalRentItemCount = rentItemCount
            customersNewOrderArr.push(order)
          }
        }
      }) 
      setAllCustomerFeedOrderData(customersNewOrderArr)

      if(localStorage.getItem('customerViewedProductsArr')) {
        let customerViewedProductArr = JSON.parse(''+localStorage.getItem('customerViewedProductsArr'))
        if(customerViewedProductArr && customerViewedProductArr.length > 500) {
          addCustomerViewProductActivity(customerViewedProductArr)
          localStorage.removeItem('customerViewedProductsArr')
          let currentTimestamp : any = moment()?.valueOf();
          currentTimestamp = parseInt(currentTimestamp / 60000)
          jsCookie.set('lastProductViewActivityUpdateTime', currentTimestamp)
        }
      }

    }
  }

  const _addProductToCart = (product: any, numberOfProd: string, from: any) => {
    setMsg({ type: "", msg: "" })
    dispatch(getCustomerTags(customer.customer_id)).then(() => {
      let prodImagesSingle:any = []
      if(product?.images?.length > 0){
        product?.images?.map((val)=>{
          prodImagesSingle.push(val?.product_image_url)
          })
      }
      let productObj = {
        created_by: product?.created_by,
        updated_by: product?.updated_by,
        created_at: product?.created_at,
        updated_at: product?.updated_at,
        colors: product?.colors,
        discount: product?.productSku?.length > 0 ? product?.productSku?.[0]?.discount : 0,
        images: prodImagesSingle,
        product_availability: product?.product_availability,
        product_catagory: product?.isClothing ? 1 : 2 ,
        product_description: product?.product_description,
        product_detail: product?.product_detail,
        product_handle: product?.product_handle,
        product_id: product?.product_id,
        product_notes: product?.product_notes,
        product_shopify_id: product?.product_shopify_id,
        product_thumbnail: product?.product_thumbnail,
        product_title: product?.product_title,
        product_type_id: product?.product_type_id,
        product_vendor_id: product?.product_vendor_id,
        published_at: product?.published_at,
        retail: product?.productSku?.length > 0 ? product?.productSku?.[0]?.retail : 0,
        sale_price:  product?.productSku?.length > 0 ? product?.productSku?.[0]?.sale_price : 0,
        sizes: product?.productSku,
        size_and_fit: product?.size_and_fit,
        tags: product?.tags,
        vendor: product?.vendor_name,
        vendor_handle: product?.vendor_handle,
        status: product?.status,
        status_for_sale: product?.status_for_sale,
        status_for_sale_nonsubscriber: product?.status_for_sale_nonsubscriber,
        productExternalColorAttribute: product?.attributes?.external_color?.length > 0 ? product?.attributes?.external_color?.[0]?.name : ''
      }
      let customerTag = customer.tag_list.join().toLowerCase();
      let nItems = false;

      if (customerTag.indexOf('items') > -1 && customerTag.indexOf('earlycheckin') < 0) {
        nItems = true;
      }else if(customerTag.indexOf('currentlyborrowing') > -1 && customerTag.indexOf('earlycheckin') < 0 && (customerTag.indexOf('items') > -1 || customerTag.indexOf('additionalitem') > -1)){
        nItems = true;
      }
      // if (customerTag.indexOf('added items') > -1) {
      //   nItems = false;
      // }
      if (customerTag.indexOf('currentlyborrowing') > -1 && !nItems) {
        setMsg({ type: from, msg: "Oops! You have to send back your current items before checking out new ones." })
        return
      }

      if (numberOfProd == "multiple") {
        let productObjArr = product
        let productArr = []
        let isSizeNotSelectedError: any = false

        productObjArr.length > 0 && productObjArr.map((prod, ind) => {
          if (prod && prod.product_id != undefined) {
            if (prod.selectedSize != "" && prod.selectedSize != undefined && prod.selectedSize != true) {
              productArr.push(prod)
            }
            else {
              if (prod.productSku.length == 1 && prod.productSku[0]?.option1 == 'NONE') {
                prod.selectedSize = "NONE";
                productArr.push(prod)
              }
              else {
                isSizeNotSelectedError = true
                setMsg({ type: from, msg: "Please select a size" })
                return
              }
            }
          }
        })


        // console.log(product, 'product')
        if (isSizeNotSelectedError == true) return

        let customer_id = customer.customer_id;

        try {

          if (productArr.length == 0) {
            setMsg({ type: from, msg: "" })
            return
          }

          let cartArr: any = []
          productArr.length > 0 && productArr.map((_prod, ind) => {
            let _cartItemType = 1;
            let extra = "";
            let isShoes = false
            let size = _prod.selectedSize
            let sizeData = _prod.productSku.filter((obj: any) => { return (size == obj.option1) })
            let prodImages:any = []
            if(product?.images?.length > 0){
              product?.images?.map((val)=>{
                    prodImages.push(val?.product_image_url)
                })
            }
            let productObjForMultipleItems = {
              created_by: _prod?.created_by,
              updated_by: _prod?.updated_by,
              created_at: _prod?.created_at,
              updated_at: _prod?.updated_at,
              colors: _prod?.colors,
              discount: _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.discount : 0,
              images: prodImages,
              product_availability: _prod?.product_availability,
              product_catagory: _prod?.isClothing ? 1 : 2 ,
              product_description: _prod?.product_description,
              product_detail: _prod?.product_detail,
              product_handle: _prod?.product_handle,
              product_id: _prod?.product_id,
              product_notes: _prod?.product_notes,
              product_shopify_id: _prod?.product_shopify_id,
              product_thumbnail: _prod?.product_thumbnail,
              product_title: _prod?.product_title,
              product_type_id: _prod?.product_type_id,
              product_vendor_id: _prod?.product_vendor_id,
              published_at: _prod?.published_at,
              retail: _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.retail : 0,
              sale_price:  _prod?.productSku?.length > 0 ? _prod?.productSku?.[0]?.sale_price : 0,
              sizes: _prod?.productSku,
              size_and_fit: _prod?.size_and_fit,
              tags: _prod?.tags,
              vendor: _prod?.vendor_name,
              vendor_handle: _prod?.vendor_handle,
              status: _prod?.status,
              status_for_sale: _prod?.status_for_sale,
              status_for_sale_nonsubscriber: _prod?.status_for_sale_nonsubscriber,
              productExternalColorAttribute: _prod?.external_color?.attribute
          }

            if (_prod.tags.indexOf('garment-bag') > -1) {
              extra = 'jacket'
            }

            let sizeObj = getSizeInfo(sizeData[0].inventory_quantity, sizeData[0].new_inventory_quantity, customer.has_subscription)
            let newItem: any = false;
            if (!sizeObj.has_usedItem) {
              newItem = true
            }

            let cartObj = {
              customer_id: customer_id,
              product_id: _prod.product_id,
              size: size == 'NONE' ? '' : size,
              cartItemType: _cartItemType,
              newItem: newItem,
              extraParameters: extra,
              sku_id: sizeData[0].skuid,
              isShoes: isShoes,
              customer_email: customer.email,
              sku_code: sizeData[0].sku1,
              IsLogin: true,
              product_details: productObjForMultipleItems
            }
            cartArr.push(cartObj)
          })

          dispatch(addMultipleProductToCart(() => dispatch(triggerCartPopper(true)), cartArr, setMsg, true, from))

        } catch (error) {
          console.log('add cart stylish error', error)
        }
      }
      else {
        let size = product.selectedSize;

        if (product.selectedSize == undefined && product.productSku.length == 1 && product.productSku[0]?.option1 == 'NONE') {
          size = "NONE"
        }


        if (size == "" || size == undefined || size == true || size == false) {
          setMsg({ type: from, msg: "Please select a size" })
          return
        }

        let customer_id = customer.customer_id;
        let product_id = product.product_id;

        try {
          let _cartItemType = 1;
          let extra = "";
          let isShoes = false
          let sizeData = product.productSku.filter((obj: any) => { return (size == obj.option1) })

          if (product.tags.indexOf('garment-bag') > -1) {
            extra = 'jacket'
          }

          let sizeObj = getSizeInfo(sizeData[0].inventory_quantity, sizeData[0].new_inventory_quantity, customer.has_subscription)
          let newItem: any = false;
          if (!sizeObj.has_usedItem) {
            newItem = true
          }

          let cartObj = {
            customer_id: customer_id,
            product_id: product_id,
            size: size == 'NONE' ? '' : size,
            cartItemType: _cartItemType,
            newItem: newItem,
            extraParameters: extra,
            sku_id: sizeData[0].skuid,
            isShoes: isShoes,
            customer_email: customer.email,
            sku_code: sizeData[0].sku1,
            IsLogin: true,
            product_details: productObj
          }
          dispatch(addProductToCartFromFunctionComp(() => dispatch(triggerCartPopper(true)), cartObj, setMsg, true, from))

        } catch (error) {
          console.log('add cart stylish error')
        }
      }
    })
  }

  const toggleFavourite = (e: any, id: any, isLogin: any, boardClass: any) => {
    if (isLogin != "not_login") {
      let selector = e.target.classList
      if (selector.toString().indexOf('far') > -1) {
        if (e.target.parentElement)
          selector.remove('far')
        selector.add('fas')
        dispatch(addRemoveFavoriteItem(parseInt(id), 1, true))
      } else if (screen.width > 767) {
        selector.remove('fas')
        selector.add('far')
        if (e.target.parentElement)
          e.target.parentElement.classList.remove('filled')
        dispatch(addRemoveFavoriteItem(parseInt(id), 0, true))
        const selectedBoards = [].slice.call(e.target.parentElement.children[1].children[0].children)
        selectedBoards.filter(boards => {
          if (boards.classList) {
            boards.classList.remove('selected')
          }
        })
      }
      if (screen.width <= 767) {
        let boardsList = document.querySelectorAll('.newBoard-tooltip')
        boardsList.forEach((val, ind) => {
          if (boardsList[ind]) {
            boardsList[ind].style.display = "none"
          }
        })
        let boardId = 'board_' + id
        if (document.getElementById(boardId)) {
          document.getElementById(boardId).style.display = 'block'
          if (document.querySelector('.backdrop-boardTooltip'))
            document.querySelector('.backdrop-boardTooltip').style.display = 'block'
        }
      }
    } else {
      setOpenSignupModal(!openSignupModal)
    }
  }

  const orderProductHtml = (product: any, index: number, slideIndex:any, from:any) => {

    if((from == 'mobile' && window.innerWidth > 767) || (from == 'desktop' && window.innerWidth < 767)) return
    if(customer != "not_login") {
      if(currentSwiperSlide == slideIndex && alreadyViewProductArr.indexOf(product.product_id) == -1) {
        alreadyViewProductArr.push(product.product_id)
        let obj = {
          "product_id": product.product_id,
          "customer_id": customer.customer_id,
          "season": "",
          "source": "customer-order-feed",
          "activity": 1                   
        }
        // saveCustomerViewProductActivityLocal([obj])
        if(currentSwiperSlide > 1) {
          saveCustomerViewProductActivityLocal([obj])
        }
        else if (currentSwiperSlide <= 1) {
          if(currentSwiperSlide == 0) {
            tempViewProdArrObj.push(obj)
          }
          else if(currentSwiperSlide == 1) {
            if(tempViewProdArrObj.length > 0) {
              saveCustomerViewProductActivityLocal(tempViewProdArrObj)
              tempViewProdArrObj = []
            }
            saveCustomerViewProductActivityLocal([obj])
          }
        }
      }
    }

    let isImageOld = true
    let vlink = (product && product.vendor_handle != null && product.vendor_handle != "" && product.vendor_handle != undefined) ? product.vendor_handle : 'vendor';
    vlink = "/product/" + vlink + "/" + product.product_handle;
    if (product?.tags?.filter((item: any) => { if (item == 'updated-gray') return true; else return false }).length > 0) {
      isImageOld = false;
    }

    return (
      <div style={{ flex: "1", position: "relative" }}>
        <Link legacyBehavior href="/product/[...handle]" as={vlink}>
          <a target='_blank'>
            {product?.images ? <img className={`cursor_pointer ${'img_' + index} `} src={Gconfig.productImageUrl + product?.images[0]?.product_image_url + "?profile=b2x3"} alt={`${capitializeString(product?.attributes?.external_color?.[0]?.attribute)} ${capitializeString(product?.product_title)} by ${product?.vendor_name} Front View`} /> : ""}
          </a>
        </Link>
        <div className='favBox customerFeed'>
          <i className={product.is_favorite ? ` fas fa-heart ${isImageOld ? ' pink-heart' : ' pink-heart-new'}` : ` far fa-heart ${isImageOld ? ' pink-heart' : ' pink-heart-new'}`} onClick={(e) => toggleFavourite(e, product.product_id, customer, 'stylishpick')} onTransitionEnd={(e) => toggleFavourite(e, product.product_id, customer, 'completeLook')} id={'product_' + product.product_id} aria-hidden="true"></i>
          {customer != 'not_login' && <BoardsListTooltip boardUniqueId={'stylishlook'} boards={customerBoards.customerBoards} item={product} favoriteBoards={product.favoriteBoards} comp={'products'} />}
        </div>
      </div>
    )
  }

  const orderBoxHtml = (orderData: any, index: any, ind:any, from:string) => {

    let orderItems = orderData.ordersFeedProductPairs
    // let customerName:any = '' + orderData.customerRandomName?.toLowerCase()
    let customerName: any = 'Member'
    let orderType = orderData.configurationId
    let cityName = orderData.cityName?.toLowerCase()
    let configuration = orderData.configuration ? orderData.configuration : ""
    let stateName = getProvinceCode(orderData.stateName)
    return (
      <div className={styles.product_container} key={'orderbox'+index}>
        <p className={styles.order_customer_name} > <span style={{ color: "#000", textOverflow: "inherit", textTransform: "none" }}> Member in  </span>  {cityName}{stateName != "" && stateName != undefined ? ',' : ""} {stateName}  </p>
        {/* <span className={styles.order_plan_name}> {configuration} </span> */}
        {/* <p style={{ fontSize:"10px" }}> order type {orderType} </p> */}

        <div className={styles.product_container_inner}>
          {orderType == 1 ?
            <div className={`product_box_1 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[1], 1, ind, from)}
                {orderItems[2] != undefined ? orderProductHtml(orderItems[2], 2, ind, from) : ""}
              </div>
            </div> :
            ""}

          {orderType == 2 ?
            <div className={`product_box_2 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                {orderItems[3] != undefined ? orderProductHtml(orderItems[3], 3, ind, from) : ""}
              </div>
            </div>
            : ""}

          {orderType == 3 ?
            <div className={`product_box_3 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                {orderItems[3] != undefined ? orderProductHtml(orderItems[3], 3, ind, from) : ""}
              </div>
            </div>
            : ""}

          {orderType == 4 ?
            <div className={`product_box_4 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                <div className={'produt_inner_box_row'}>
                  {orderProductHtml(orderItems[3], 3, ind, from)}
                  {orderItems[4] != undefined ? orderProductHtml(orderItems[4], 4, ind, from) : ""}
                </div>
              </div>
            </div>
            : ""}

          {orderType == 5 ?
            <div className={`product_box_5 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                {orderProductHtml(orderItems[3], 3, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[4], 4, ind, from)}
                {orderItems[5] != undefined ? orderProductHtml(orderItems[5], 5, ind, from) : ""}
              </div>
            </div>
            : ""}

          {orderType == 6 ?
            <div className={`product_box_6 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                {orderProductHtml(orderItems[3], 3, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[4], 4, ind, from)}
                {orderProductHtml(orderItems[5], 5, ind, from)}
                {orderItems[6] != undefined ? orderProductHtml(orderItems[6], 6, ind, from) : ""}
              </div>
            </div>
            : ""}


          {orderType == 7 ?
            <div className={`product_box_7 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[2], 2, ind, from)}
                {orderProductHtml(orderItems[3], 3, ind, from)}
                {orderProductHtml(orderItems[4], 4, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[5], 5, ind, from)}
                {orderItems[6] != undefined ? orderProductHtml(orderItems[6], 6, ind, from) : ""}
                {orderItems[7] != undefined ? orderProductHtml(orderItems[7], 7, ind, from) : ""}
              </div>
            </div>
            : ""}

          {orderType == 8 ?
            <div className={`product_box_8 product_box_of`}>
              <div className={'produt_inner_box'}>
                {orderProductHtml(orderItems[0], 0, ind, from)}
                {orderProductHtml(orderItems[1], 1, ind, from)}
                {orderProductHtml(orderItems[2], 2, ind, from)}
              </div>
              <div className={'produt_inner_box'}>
                {orderItems[3] != undefined ? orderProductHtml(orderItems[3], 3, ind, from) : ""}
                {orderItems[4] != undefined ? orderProductHtml(orderItems[4], 4, ind, from) : ""}
                {orderItems[5] != undefined ? orderProductHtml(orderItems[5], 5, ind, from) : ""}
              </div>
              <div className={'produt_inner_box'}>
                {orderItems[6] != undefined ? orderProductHtml(orderItems[6], 6, ind, from) : ""}
                {orderItems[7] != undefined ? orderProductHtml(orderItems[7], 7, ind, from) : ""}
                {orderItems[8] != undefined ? orderProductHtml(orderItems[8], 8, ind, from) : ""}
              </div>
            </div>
            : ""}

        </div>

        <div className={styles.circle_cart_container}
          onClick={() => {
            setSelectedCustomerFeedObj(orderItems);
            setMsg({ type: "", msg: "" })
            setstylistSuggestionsPopup(true);
          }}>
          <svg className={styles.add_image} xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
            <path d="M7.5 0.00012207C3.35685 0.00012207 0 3.35698 0 7.50012C0 11.6433 3.35685 15.0001 7.5 15.0001C11.6431 15.0001 15 11.6433 15 7.50012C15 3.35698 11.6431 0.00012207 7.5 0.00012207ZM11.8548 8.3469C11.8548 8.54649 11.6915 8.7098 11.4919 8.7098H8.70968V11.4921C8.70968 11.6917 8.54637 11.855 8.34677 11.855H6.65323C6.45363 11.855 6.29032 11.6917 6.29032 11.4921V8.7098H3.50806C3.30847 8.7098 3.14516 8.54649 3.14516 8.3469V6.65335C3.14516 6.45375 3.30847 6.29044 3.50806 6.29044H6.29032V3.50819C6.29032 3.30859 6.45363 3.14528 6.65323 3.14528H8.34677C8.54637 3.14528 8.70968 3.30859 8.70968 3.50819V6.29044H11.4919C11.6915 6.29044 11.8548 6.45375 11.8548 6.65335V8.3469Z" fill="#E89CAE" />
          </svg>
          <img className={styles.shopping_image} src={Gconfig.fashionpassImageStorage + "assets/ShoppingBag.svg"} alt="Cart icon" aria-label='Add to your cart to start an order.' />
        </div>
      </div>
    )
  }

  const updatingPreference = ({ success, response, profileValue }: any) => {
    if (success == true) {
      if (document.querySelector('#customerorder_swiper')) {
        const swiper = document.querySelector('#customerorder_swiper').swiper;
        swiper.slideTo(0);
      }
      getCustomerFeedBySize(true)
      setprefrenceSuggestionsPopup(false)
    }
    else {
      setprefrenceSuggestionsPopup(false)
    }

  }

  const addSaveForLaterBtnClick = async (product: any, from: any, pickedItemplace: any) => {
    let obj: any = [...selectedCustomerFeedObj]

    setMsg({ type: "", msg: "" })

    let saveLaterTotalCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
    if (saveLaterTotalCount >= 50) {
      setMsg({ type: from, msg: "max number of save for later reached" })
      return
    }

    setShowSizeLaterPopupIndex(-1)
    let selectedSkuID = ""
    if (product.selectedSize == undefined || product.selectedSize == "") {
      setShowSizeLaterPopupIndex(from)
      return
    }
    else {
      let skuArr = product.productSku
      if (skuArr && skuArr.length > 0) {
        skuArr.map((sku: any, index: any) => {
          if (sku.option1 == product.selectedSize) {
            selectedSkuID = sku.skuid
          }
        })
      }
    }

    if (selectedSkuID == "") return

    let data = {
      "product_id": product.product_id,
      "status": 1,
      "skuid": selectedSkuID
    }


    let responce = await addSaveLaterProduct(data)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) + 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))

      obj[pickedItemplace].is_save_for_later_skuid = selectedSkuID
      obj[pickedItemplace].is_save_for_later = true
      obj[pickedItemplace].is_save_later_now = true

      if (document.querySelector('.header_save_later_btn') != null) {
        document.querySelector('.header_save_later_btn').style.display = "none"
        document.querySelector('.header_save_later_btn_filled').style.display = "block"
        if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "none"

        setTimeout(() => {
          document.querySelector('.header_save_later_btn').style.display = "block"
          document.querySelector('.header_save_later_btn_filled').style.display = "none"
          if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "flex"
        }, 2000)
      }

      // console.log('obj', obj)

      setSelectedCustomerFeedObj(obj)
    }
  }

  const addSaveForLaterFromPopup = async (product: any, pickedItemplace: any, skuId: any) => {
    let obj: any = [...selectedCustomerFeedObj]

    setMsg({ type: "", msg: "" })


    let data = {
      "product_id": product.product_id,
      "status": 1,
      "skuid": skuId
    }

    let responce = await addSaveLaterProduct(data)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) + 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))

      obj[pickedItemplace].is_save_for_later_skuid = skuId
      obj[pickedItemplace].is_save_for_later = true
      obj[pickedItemplace].is_save_later_now = true

      setShowSizeLaterPopupIndex(-1)

      if (document.querySelector('.header_save_later_btn') != null) {
        document.querySelector('.header_save_later_btn').style.display = "none"
        document.querySelector('.header_save_later_btn_filled').style.display = "block"
        if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "none"

        setTimeout(() => {
          document.querySelector('.header_save_later_btn').style.display = "block"
          document.querySelector('.header_save_later_btn_filled').style.display = "none"
          if (document.querySelector('.showSFLIcon')) document.querySelector('.showSFLIcon').style.display = "flex"
        }, 2000)
      }
      setSelectedCustomerFeedObj(obj)
    }
  }

  const removeFromSaveLater = async (prooduct: any, pickedItemplace: any) => {
    let obj: any = [...selectedCustomerFeedObj]
    let data = {
      "product_id": prooduct.product_id,
      "status": 0
    }
    let dataArr = [data]
    let responce = await updateSaveLaterProduct(dataArr)
    if (responce.success) {
      let prevCount = (cart.savedLaterCount && cart.savedLaterCount.totalCount) ? cart.savedLaterCount.totalCount : 0;
      let savelatercountobj = { totalCount: (parseInt(prevCount) - 1) }
      dispatch(fetchCartSaveLaterSuccess(savelatercountobj))
      obj[pickedItemplace].is_save_for_later_skuid = ""
      obj[pickedItemplace].is_save_for_later = false
      delete obj[pickedItemplace].is_save_later_now
      setSelectedCustomerFeedObj(obj)
    }
  }

  function saveLaterProductSizesButtons(product_dataInfo: any, pickedItemplace: any) {
    let has_subscription = false;
    if (customer != 'not_login' && customer?.has_subscription) {
      has_subscription = true
    }
    return (
      <span className='size_inner_container save_later_size_btn'  >
        {product_dataInfo.productSku.map((value: any, index: any) => {
          let hide_sku_arr = ['MU437-6', 'MU437-7', 'MU437-8', 'MU437-9', 'MU437-10']
          if (value.sku && hide_sku_arr.indexOf(value.sku) > -1) {
            return;
          }
          return <span key={'s'+index} className="saveLaterSize  save_later_size_btn"  style={{ color: '#000' }} onClick={() => addSaveForLaterFromPopup(product_dataInfo, pickedItemplace, value.skuid)}> {value.option1} </span>
        })}
      </span>
    )
  }

  const handleSize = (selectedSize: string, pickedItemplace: any) => {
    let obj: any = [...selectedCustomerFeedObj]
    obj[pickedItemplace].selectedSize = selectedSize
    setSelectedCustomerFeedObj(obj)

    let skuObj: any = null
    let skuArr = obj[pickedItemplace].productSku ? obj[pickedItemplace].productSku : [];
    skuArr.length > 0 && obj[pickedItemplace].productSku.map((sku: any, ind: any) => {
      if (selectedSize == sku.option1) {
        skuObj = sku
      }
    })

    if (Gconfig.environment == 'production' && location.host == 'www.fashionpass.com' && skuObj != null) {
      if (gtag) {
        gtag({
          event: "select_item",
          ecommerce: {
            items: [
              {
                item_id: skuObj.skuid,
                item_name: obj[pickedItemplace].product_title,
                currency: "USD",
                discount: skuObj.discount,
                index: 0,
                item_brand: obj[pickedItemplace].vendor_name,
                item_list_id: obj[pickedItemplace].product_id,
                item_list_name: obj[pickedItemplace].product_title,
                item_variant: obj[pickedItemplace].externalColor ? obj[pickedItemplace].externalColor.attribute : "",
                price: skuObj.price,
                quantity: 1
              }
            ]
          }
        });
      }
    }
  }



  const productSizeBtnHtml = (product_dataInfo: any, type: any, pickedItemIndex: any) => {
    let has_subscription = false;
    if (customer != "not_login" && customer.has_subscription) {
      has_subscription = true
    }

    return (
      <>
        {/* <MediaQuery minWidth={768}> */}
        <div className={"swatch"} >
          <div className={styles.swatch_style_fix}>
            {product_dataInfo.productSku.map((value: any, index: any) => {
              let sizeObj = getSizeInfo(value.inventory_quantity, value.new_inventory_quantity, has_subscription)
              let outOfStock = false
              if (value.option1 == 'NONE') return "";
              outOfStock = (value.inventory_quantity < 1) || (!has_subscription && (sizeObj.has_usedItem && !sizeObj.has_usedItem) || (!sizeObj.has_usedItem && !sizeObj.has_newItem))
              if (value.inventory_quantity < 1 || outOfStock) return
              return (
                <span key={'swatchc'+index}>
                  <label className={`${selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == value.option1 ? styles.bg_selected_size : ""}`} onClick={() => handleSize(value.option1, pickedItemIndex)} disabled={value.inventory_quantity < 1 || outOfStock} >{value.option1}</label>
                </span>
              )
            })}
          </div>
        </div>
        {/* </MediaQuery> */}
        {/* <MediaQuery maxWidth={767} >
               {(product_dataInfo.productSku.length == 1 && product_dataInfo.productSku[0].option1 == 'NONE') ? "" : 
                <select  name="size" className={(selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == "" || selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == undefined)  ? styles.select_input_2 : styles.select_input} onChange={(e) => handleSize(e.target.value, pickedItemIndex)} >
                    {product_dataInfo.productSku.map((size:any, index:any) => {
                        let sizeObj = getSizeInfo(size.inventory_quantity, size.new_inventory_quantity, has_subscription)
                        let outOfStock = false
                        if (size.option1 == 'NONE') return "";
                        outOfStock = (size.inventory_quantity < 1) || (!has_subscription && (sizeObj.has_usedItem && !sizeObj.has_usedItem) || (!sizeObj.has_usedItem && !sizeObj.has_newItem))
                        if(size.inventory_quantity < 1 || outOfStock) return
                    return (
                            <React.Fragment key={'size' + index}>
                                {index == 0 ? <option value="" disabled selected={(selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == "" || selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == undefined)} style={{ color:"#000 !important" }}  > Select a size </option> : ""}
                                <option key={"size" + index} selected={selectedCustomerFeedObj[pickedItemIndex]?.selectedSize == size.option1} name={size.option1} value={size.option1}> {size.option1} </option>
                            </React.Fragment>
                        )
                    })}
                </select> }
            </MediaQuery> */}
      </>
    )
  }

  function rentalProductDetailHtml(prod: any, pickedItemIndex: any, from: any) {
    let vlink = (prod.vendor_handle != null && prod.vendor_handle != "" && prod.vendor_handle != undefined) ? prod.vendor_handle : 'vendor';
    vlink = "/product/" + vlink + "/" + prod.product_handle;
    return (
      <div className={`product_box ${selectedCustomerFeedObj.length == 3 ? 'ml-0' : ''}`}>
        <div className={"product_popup_image"}>
          <Link legacyBehavior href="/product/[...handle]" as={vlink}>
            <a target='_blank'>
              <img src={Gconfig.productImageUrl + prod?.images[0]?.product_image_url + "?profile=b2x3"} alt={`${capitializeString(prod?.attributes?.external_color?.[0]?.attribute)} ${capitializeString(prod?.product_title)} by ${prod?.vendor_name} Front View`} />
            </a>
          </Link>
        </div>
        <div className={"product_info"}>
          <p className={"product_title"} >{prod?.product_title?.toLowerCase()}</p>
          <p className={"vendor"} >{prod.vendor_name ? prod.vendor_name?.toLowerCase() : ""}</p>
          <p className={"color"} > color: {prod?.externalColor?.attribute}</p>
          {prod?.attributes?.size_and_fit ? <p className={"size_fit"}> Size: <span>{prod?.attributes?.size_and_fit[0]?.attribute}</span></p> : <p className={"size_fit"}></p>}
          <div className={"product_size_button"}>
            {productSizeBtnHtml(prod, 'rent', pickedItemIndex)}
          </div>

          {
            (showSaveForLaterBtn == true || isCartLimitReached(cart.cart, customer, selectedCustomerFeedObj[pickedItemIndex].product_catagory).isCartLimitReached == true) ?
              <>
                {prod.is_save_for_later == false ?
                  <div className={"product_rent_button sfl_button save_later_size_btn"} onClick={() => addSaveForLaterBtnClick(prod, from, pickedItemIndex)} >
                    <span id="saveForLaterTooltip"> SAVE FOR LATER </span>
                    {/* <MediaQuery minWidth={768}>
                    {showSizeLaterPopupIndex == -1  ? 
                    <Tooltip placement="bottom" isOpen={true} autohide={false} toggle={()=>setSaveForLaterTooltip(!saveForLaterTooltip)} target="saveForLaterTooltip" className="saveForLaterTooltip_stylistpick">
                        Use this icon to add this product to your Saved For Later section in your cart!
                    </Tooltip> : "" }
                </MediaQuery> */}
                    {showSizeLaterPopupIndex == from ?
                      <span className='saveLaterAvailableSizeContainer customerFeedSFLSizeContainer save_later_size_btn' style={{ left: 'auto', marginTop: "30px" }}>
                        <span className='pickSizeText save_later_size_btn'> Pick a Size </span>
                        {saveLaterProductSizesButtons(prod, pickedItemIndex)}
                      </span> : ""}
                  </div> :
                  <div className={"product_rent_button alreadySave_btn"} onClick={() => removeFromSaveLater(prod, pickedItemIndex)} >
                    <span id="saveForLaterTooltip"> {prod.is_save_later_now ? "SUCCESSFULLY SAVED" : "ALREADY SAVED"} </span>
                    {/* <MediaQuery minWidth={768}>
                    {showSizeLaterPopupIndex == -1 ? 
                    <Tooltip placement="bottom" isOpen={true} autohide={false} toggle={()=>setSaveForLaterTooltip(!saveForLaterTooltip)}  target="saveForLaterTooltip" className="saveForLaterTooltip_stylistpick">
                         This item is in your Saved For Later section in your cart. You can click again to remove it.
                    </Tooltip> 
                    : "" }
                </MediaQuery> */}
                  </div>
                }
              </> :
              <div className={"product_rent_button"} onClick={() => _addProductToCart(selectedCustomerFeedObj[pickedItemIndex], 'single', from)} ><span> RENT THIS ITEM </span></div>
          }
          {from == msg.type ? <p className={"meesage_SFL"} > {(selectedCustomerFeedObj[pickedItemIndex].selectedSize == "NONE" && msg.msg.indexOf('size has already been') > -1) ? 'This item has already been added to your shopping bag.' : msg.msg} </p> : <p className={"meesage_SFL"} > </p>}
        </div>
      </div>
    )
  }

  function onSwiperSlideChange(e: any) {
    if (alreadFetchedAllPairData) return
    setCurrentSwiperSlide(e.activeIndex)
    let totalSlides = e.slides ? e.slides.length : 1;
    if (totalSlides > e.activeIndex && (totalSlides - e.activeIndex) <= 3 && alreadyFetchingNewData == false) {
      setAlreadyFetchingNewData(true)
      // setCurrentPage(currentPage+1)
      getCustomerFeedBySize(false)
    }
  }

  return (
    <>
      {/* {minimumReqOrdersFound  ?  */}
      <>
        {
          allCustomerFeedOrderData.length == 0 ?
            <div className={styles.order_feed_container}>
              {minimumReqOrdersFound && fromMyOrder == false ? <h1 className={styles.order_feed_heading}>Trending Member Orders</h1> : ""}
              {!alreadyFetchingNewData && minimumReqOrdersFound ?
                <div className={styles.dummyLoading}>
                  <Loader small={true} />
                </div>
                : ""}
            </div> : ''
        }
        {allCustomerFeedOrderData.length > 0 ?
          <div className={styles.order_feed_container}>
            {(minimumReqOrdersFound || inCompleteProfileOverlay)  && fromMyOrder == false ? <h1 className={styles.order_feed_heading}>Trending Member Orders</h1> : ""}
            <p className={styles.order_feed_real_member}>See what real members in your area are ordering!</p>
            {inCompleteProfileOverlay != true ? <p className={`${styles.product_filters_applied} container d-none d-md-block`}> <span className='cursor-pointer' onClick={() => { setprefrenceSuggestionsPopup(true) }} >  See what filters we applied </span> </p> : ""}
            {inCompleteProfileOverlay ?
              <div className={styles.incomp_profile_overlay_container} >
                {isPairNotFound ?
                  <>
                    <p className={styles.overlay_text} >  ALL OUTFITS ARE RENTED OUT IN YOUR SIZE! <br /> CHANGE YOUR SIZES TO SEE OTHER OPTIONS. </p>
                    <div className={styles.overlay_btn_box} >
                      <button className={styles.overlay_btn} onClick={() => { setprefrenceSuggestionsPopup(true) }}> UPDATE SIZE PREFERENCES </button>
                    </div>
                  </> :
                  <>
                    <p className={styles.overlay_text} > ADD YOUR SIZES SO WE CAN RECOMMEND <br /> OUTFITS AVAILABLE IN YOUR SIZE! </p>
                    <div className={styles.overlay_btn_box} >
                      <Link className={styles.overlay_btn} href="/my-account?tab=1">COMPLETE YOUR PROFILE </Link>
                    </div>
                  </>}
              </div> : ""}

            {alreadyFetchingNewData ?
              <div className={styles.loader}>
                <Loader small={true} />
              </div>
              : ""}

            {minimumReqOrdersFound ? <div className={`${styles.order_feed_sub_container_main} ${styles.customer_order_feed_box_area} ${(inCompleteProfileOverlay || alreadyFetchingNewData) ? styles.order_feed_sub_container_main_disabled : ""} `} >
              <MediaQuery minWidth={768}>
                <Swiper
                  modules={[Pagination, Navigation]}
                  slidesPerView={1}
                  centeredSlides={true}
                  initialSlide={currentSwiperSlide}
                  navigation={true}
                  spaceBetween={15}
                  className={`plan-carosel-mobile plan-carosel-mobile-customer-feed plan-carosel-desktop-customer-feed`}
                  onSlideChange={(e) => { onSwiperSlideChange(e) }}
                  id="customerorder_swiper"
                  simulateTouch={false}
                >
                  {getRangeBetweenTwoNumbers({ start: 1, end: Math.ceil(allCustomerFeedOrderData.length / noOfIteminPerSlide) }).map((val, ind) => {
                    return (
                      <SwiperSlide key={'main_c'+ind}>
                        <div key={'range_key' + val} className={isSmallScreen ? `${styles.sliderStyle} d-flex justify-content-center` : 'd-flex' } >
                          {allCustomerFeedOrderData.map((orderData: any, d_ind: any) => {
                            d_ind = d_ind + 1
                            let startingPoint = ((val - 1) * noOfIteminPerSlide) + (!isSmallScreen ? 1 : 2);
                            let endingPoint = val * noOfIteminPerSlide
                            if (d_ind >= startingPoint && d_ind <= endingPoint) {
                              return (
                                orderBoxHtml(orderData, d_ind, ind, 'desktop')
                              )
                            }
                            else return
                          })
                          }
                        </div>
                      </SwiperSlide>
                    )
                  })
                  }

                </Swiper>

              </MediaQuery>
              <MediaQuery maxWidth={767}>
                <Swiper
                  modules={[Pagination, Navigation, FreeMode]}
                  slidesPerView={1.15}
                  centeredSlides={true}
                  initialSlide={currentSwiperSlide}
                  navigation={true}
                  spaceBetween={15}
                  onSlideChange={(e) => { onSwiperSlideChange(e) }}
                  id="customerorder_swiper"
                  className={`plan-carosel-mobile plan-carosel-mobile-customer-feed`}
                  simulateTouch={true}
                  // freeMode={true}
                >
                  {allCustomerFeedOrderData.map((orderData: any, d_ind: any) => {
                    return (
                      <SwiperSlide key={'swiper'+d_ind}>
                        {orderBoxHtml(orderData, d_ind, d_ind, 'mobile')}
                      </SwiperSlide>
                    )
                  })
                  }
                </Swiper>
              </MediaQuery>
              {inCompleteProfileOverlay != true ? <p className={`${styles.product_filters_applied} container d-block d-md-none`}> <span className='cursor-pointer' onClick={() => { setprefrenceSuggestionsPopup(true) }} >  See what filters we applied </span> </p> : ""}
            </div> : ""}
          </div>
          : <></>}

        <Modal className="product_popup_modal_CustomerFeed" style={selectedCustomerFeedObj.length == 3 ? { width: "600px" } : {}} size="lg" centered isOpen={stylistSuggestionsPopup} toggle={() => { setstylistSuggestionsPopup(!stylistSuggestionsPopup) }}>
          <ModalHeader className={"product_modal_head"} toggle={() => { setstylistSuggestionsPopup(!stylistSuggestionsPopup) }}>
          </ModalHeader>
          <ModalBody className={"product_modal_body"}>
            <div className={`body_div ${selectedCustomerFeedObj.length == 3 ? 'justify-content-center' : ''}`}>
              {
                selectedCustomerFeedObj.length > 0 && selectedCustomerFeedObj.map((prod, ind) => {
                  return (
                    rentalProductDetailHtml(prod, ind, ind)
                  )
                })
              }
            </div>

            {showSaveForLaterBtn || (isCartLimitReached(cart.cart, customer).success && isCartLimitReached(cart.cart, customer).remainingRentalCount < selectedCustomerFeedObj.selectedCustomerFeedObj) || isCartLimitReached(cart.cart, customer).isCartLimitReached == true ? '' : <div className={"product_all_item"} onClick={() => _addProductToCart(selectedCustomerFeedObj, 'multiple', -1)}  ><span> RENT ALL ITEMS </span></div>}
            {msg.type == "-1" ? <p className={"meesage_SFL"} > {msg.msg} </p> : <p className={"meesage_SFL"} > </p>}
          </ModalBody>
        </Modal>

        {prefrenceSuggestionsPopup ? <UpdatePrefrencePopup isOpen={prefrenceSuggestionsPopup} toggle={() => { setprefrenceSuggestionsPopup(!prefrenceSuggestionsPopup) }} setInCompleteProfile={setCompSetProfile} callBackFunc={updatingPreference} statesArr={statesFromCode} btn_name='See Customer Orders' selectedState={selectedState} /> : ''}
        <BodyTypePopup modal={bodyTypePopup} toggle={() => setBodyTypePopup(!bodyTypePopup)} />
        <SignupPopup isOpen={openSignupModal} />
      </>
      {/* : "" } */}

    </>
  );
};

export default CustomerOrderFeed;